import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets"
import toast from "react-hot-toast"
import storage from "framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  email: any;
  errorMsg: string,
  otp: string,
  newPassword: string,
  confirmPassword: string,
  passwordLength: boolean,
  passwordLowercase: boolean,
  passwordUppercase: boolean,
  passwordNumber: boolean,
  isPasswordFocused: boolean,
  errorMsgs: any,
  samePasswordError: any,
  passwordVisible: boolean,
  confirmPasswordVisible: boolean,
  isArabic: boolean;
  role:string;
  isLoggedIn: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: any;
  otpRequestInMailCallId: any;
  otpSendRequestCallId: any;
  resetPasswordCallId: any;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = ''

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      email: !!localStorage.getItem("authToken") ? localStorage.getItem("userEmail"): "",
      errorMsg: "",
      otp: "",
      newPassword: "",
      confirmPassword: "",
      passwordLength: false,
      passwordLowercase: false,
      passwordUppercase: false,
      passwordNumber: false,
      isPasswordFocused: true,
      errorMsgs: '',
      samePasswordError: '',
      passwordVisible: false,
      confirmPasswordVisible: false,
      isArabic: false,
      role :"",
      isLoggedIn: !!localStorage.getItem("userEmail") && !!localStorage.getItem("authToken")
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
    this.checkLanguage();
    const role = await storage.get("role");
    const userEmail = await storage.get("userEmil");
    const checkLoggedIn = await storage.get("plannerEmail")
    
    if (userEmail) {
      this.setState({
        isLoggedIn: true,
        email: userEmail,
        role
      })
    }
    if (checkLoggedIn && role == 'planner') {
      this.setState({
        isLoggedIn: true,
        email: checkLoggedIn,
        role
      })
    }

  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpRequestInMailCallId !== null &&
      this.otpRequestInMailCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.forgotPasswordResponse(responseJson)
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpSendRequestCallId !== null &&
      this.otpSendRequestCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.otpConfirmationResponse(responseJson1)
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.resetPasswordCallId !== null &&
      this.resetPasswordCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.resetPasswordResponse(responseJson2)
    }


  }

  // Customizable Area Start
  checkLanguage = () => {
    let language = localStorage.getItem('language');
    if (language == 'Arabic') {
      this.setState({ isArabic: true });
    }
    else {
      this.setState({ isArabic: false });
    }
  }

  inputType = (val: boolean) => {
    if (val) {
      return "text";
    } else {
      return "password";
    }
  }
  forgotPasswordResponse = async (responseJson: any) => {
    if (responseJson.meta) {
      if (this.state.isArabic) {
        toast.success("تم إرسال otp إلى بريدك الإلكتروني بنجاح")
      } else {
        toast.success("OTP is send to your email successfully")
      }
      window.localStorage.setItem("token", responseJson.meta.token);

      this.props.navigation.navigate("ForgotPasswordOTP");
    }
    else if (responseJson.errors) {
      const errors: any[] = responseJson.errors;
      let allerrors = '';
      errors.forEach((object: string) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
          if (value.length > 0) {
            allerrors = value
          }
        });
      });
      toast.error(allerrors)
    }

  }

  otpConfirmationResponse = async (responseJson: any) => {
    if (responseJson.messages) {
      const message: any[] = responseJson.messages;
      let allmessages = '';
      message.forEach((object: string) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
          if (value.length > 0) {
            allmessages = value
          }
        });
      });
      toast.success(allmessages)

      this.props.navigation.navigate("NewPassword");
    }
    else if (responseJson.errors) {
      const errors: any[] = responseJson.errors;
      let allerrors = '';
      errors.forEach((object: string) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
          if (value.length > 0) {
            allerrors = value
          }
        });
      });
     this.errorsInDualLanguage(allerrors);
    }
  }
  otpInvalidError = ()=>{
    if (this.state.isArabic) {
      this.setState({ errorMsg: 'كلمة المرور لمرة واحدة غير صالحة' });
    } else {
      this.setState({ errorMsg: 'OTP invalid!' });
    }
  }
  errorsInDualLanguage = (allerrors:any) =>{
    if (allerrors == "Invalid OTP code") {
     this.otpInvalidError()
      
    } else {
      this.setState({ errorMsg: allerrors });
    }
  }
  isValidEmail = (email: any) => {
    if (!email || typeof email !== 'string') {
      return false; // Invalid if email is empty or not a string
    }

    const atIndex = email.indexOf('@');
    const dotIndex = email.lastIndexOf('.');

    if (atIndex <= 0 || dotIndex <= atIndex || dotIndex === email.length - 1) {
      return false; // Invalid if @ and . are in incorrect positions
    }

    return true; // Email passes basic validation
  };

  toastError = () => {
    if (this.state.isArabic) {
      toast.error('البريد الالكتروني مطلوب')

    } else {
      toast.error('Email is required')
    }
  }
  invalidEmailToastError = () => {
    if (this.state.isArabic) {
      toast.error('البريد الإلكتروني غير صالح')

    } else {
      toast.error('Email is not valid')
    }
  }

  onEmailSubmit = async () => {
    if (this.state.email.lenth < 1 || this.state.email === "") {
      this.toastError();
    }
    else {
      const emailPattern = configJSON.emailRegExp;
      if (!this.isValidEmail(this.state.email)) {
        this.invalidEmailToastError();
      } else {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.otpRequestInMailCallId = requestMessage.messageId;
        const header = {
          "Content-Type": "application/json"
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        const attributes = {
          email: this.state.email
        }
        const data = {
          attributes: attributes
        };
        const httpBody = {
          data: data
        };

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        let endpoint ="bx_block_forgot_password/otps/planner_send_otp";
        if( this.state.role !== "planner" ){
          endpoint = "bx_block_forgot_password/otps" 
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),endpoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        window.localStorage.setItem("email_for_otp", this.state.email);
      }
    }
  }

  resetPasswordResponse = async (responseJson: any) => {
    if (responseJson.meta) {
      window.location.href = '/PasswordSuccess';
    }
    else if (responseJson.errors) {
      const errors: any[] = responseJson.errors;
      let allerrors = '';
      errors.forEach((object: string) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
          if (value.length > 0) {
            allerrors = value
          }
        });
      });
      if (allerrors == 'try a diffrent password') {
        this.setState({ errorMsgs: allerrors })
      }
      else {
        this.setState({ samePasswordError: allerrors })
      }
    } else if (responseJson.message) {
      this.setState({ samePasswordError: responseJson.message })
    }
  }
  handleGoBack = () => {
    window.history.back();
  };
  onOTPSubmit = async () => {
    if (this.state.otp.length < 4) {
     this.otpInvalidError()
    } else {
      this.setState({ "otp": "" })
      this.setState({ token: window.localStorage.getItem('token') })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpSendRequestCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json"
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        "otp_code": this.state.otp,
        "token": window.localStorage.getItem('token'),
      };
      const httpBody = {
        data: data
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/otp_confirmations"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  resendOtp = async () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpRequestInMailCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const attributes = {
      email: window.localStorage.getItem("email_for_otp")
    }
    const data = {
      attributes: attributes
    };
    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    let endpoint ="bx_block_forgot_password/otps/planner_send_otp";
    if( this.state.role !== "planner" ){
      endpoint = "bx_block_forgot_password/otps" 
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  validatePassword = async (value: string) => {
    this.setState({ newPassword: value, errorMsgs: "", samePasswordError: "" });

    if (value.length < 8) {
      this.setState({ passwordLength: false })
    }
    else {
      this.setState({ passwordLength: true })
    }
    if (!/[a-z]/.test(value)) {
      this.setState({ passwordLowercase: false })
    }
    else {
      this.setState({ passwordLowercase: true })
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({ passwordUppercase: false })
    }
    else {
      this.setState({ passwordUppercase: true })
    }
    if (!/\d/.test(value)) {
      this.setState({ passwordNumber: false })
    }
    else {
      this.setState({ passwordNumber: true })
    }
  }
  handlePasswordFocus = () => {
    this.setState({ isPasswordFocused: true });
  };

  // Event handler for input field blur
  handlePasswordBlur = () => {
    this.setState({ isPasswordFocused: false });
  };
  validationCheck = ()=>{
    if(this.state.isArabic){
      return '\n- الحد الأدنى 8 أحرف في الطول'
    }else{
      return '\n- Minimum 8 characters in length'
    }
  }
  validationCheck1 = ()=>{
    if(this.state.isArabic){
      return '\n- حرف صغير واحد على الأقل (a-z)'
    }else{
      return '\n- At least one lowercase letter (a-z)'
    }
  }
  validationCheck2 = ()=>{
    if(this.state.isArabic){
      return '\n- حرف كبير واحد على الأقل (A-Z)'
    }else{
      return '\n- At least one uppercase letter (A-Z)'
    }
  }
  validationCheck3 = ()=>{
    if(this.state.isArabic){
      return '\n- رقم واحد على الأقل (0-9)'
    }else{
      return '\n- At least one number (0-9)'
    }
  }
  validationCheck4 = ()=>{
    if(this.state.isArabic){
      this.setState({ samePasswordError: "كلمة المرور الخاصة بك غير متطابقة" })

    }else{
      this.setState({ samePasswordError: "Your password doesn't match" })

    }
  }
  
  onResetPassword = async () => {
    const passwordPattern = configJSON.passwordRegExp;
    let validationIssue = 0;

    let validationerror = 'Password must satisfy the following rules:';
    if (this.state.newPassword.length < 8) {
      validationerror += await this.validationCheck();
      validationIssue++;
    }
    if (!/[a-z]/.test(this.state.newPassword)) {
      validationerror += await this.validationCheck1();
      validationIssue++;
    }
    if (!/[A-Z]/.test(this.state.newPassword)) {
      validationerror += await this.validationCheck2();
      validationIssue++;
    }
    if (!/\d/.test(this.state.newPassword)) {
      validationerror += await this.validationCheck3();
      validationIssue++;
    }

    if (validationIssue > 0) {
      toast.error(validationerror);
    }
    else {
      if (this.state.newPassword != this.state.confirmPassword) {
        this.validationCheck4()
      }
      else {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.resetPasswordCallId = requestMessage.messageId;
        const header = {
          "Content-Type": "application/json",
          "token": localStorage.getItem('token'),
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        const data = {
          "new_password": this.state.newPassword,
          "confirm_password": this.state.confirmPassword
        };
        const httpBody = {
          data: data
        };

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        let endpoint ="bx_block_forgot_password/passwords/planner_password_reset";
        if( this.state.role !== "planner" ){
          endpoint = "bx_block_forgot_password/passwords" 
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    }
  }
  passwordVisiblityChange = async () => {
    if (this.state.passwordVisible) {
      this.setState({ passwordVisible: false })
    } else {
      this.setState({ passwordVisible: true })
    }
  }

  confirmPasswordVisiblityChange = async () => {
    if (this.state.confirmPasswordVisible) {
      this.setState({ confirmPasswordVisible: false })
    } else {
      this.setState({ confirmPasswordVisible: true })
    }
  }

  getLoginLink =  () => {
    let link = "Loginplanner";
    if(this.state.role !== "planner"){
      link = "EmailAccountLogin"
    }
    return link
  }
  // Customizable Area End
}
