import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import toast from "react-hot-toast";
import { getStorageData } from 'framework/src/Utilities';
import { OptionsType, Styles } from "react-select";
import storage from "framework/src/StorageProvider";

type RechartsMouseEvent = {
  activeLabel?: string;
  activeTooltipIndex?: number;
  chartX?: number;
  chartY?: number;
};

interface Account {
  id: number;
  bank_account_id: number;
  account_number: string;
  image: string;
  type: string;
}

interface BankOption {
  name: string;
  bank_id:number;
  accounts: Account[];
}

interface GraphDataEntry {
  month: string;
  value: number | null;
  formattedValue: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  dropdownVisiblity: any;
  dropdownVisiblity2: any;
  bankdropdownVisiblity: any;
  banks: any;
  selectedBankName:any;
  selectedBankIds:number[];
  openDetailsInfo:boolean,
  authToken:any;
  currentMonthExpanse:any;
  spendingCategory:any;
  allSpendingCategory:any;
  recentTransactions:any;
  filterTransactions:any;
  filterTransactionsMonth:any;
  filterTransactionsData:any;
  graph_month:any;
  currentPage:any;
  graph_data:any;
  oprand:any;
  currency_symbol:any;
  lmec:any;
  currencyList:any;
  selectedCurrency:any;
  TypeFilter:any;
  DateFilter:any;
  CategoryFilter:any;
  TypeFilterTemp:any;
  DateFilterTemp:any;
  CategoryFilterTemp:any;
  categoriesList:any;
  datesList:any;
  typesList:any;
  transactionDetail:boolean;
  spendingCategoryDetail:boolean;
  sub_category_id:any;
  deleteModel:boolean;
  dialogCss:any;
  transactionId:any;
  singleTransactionDetails:any;
  singleTransactionDetailsDate:any;
  updatedSubCategoryForSingleTransacion:any;
  updatedSubCategoryForSingleTransacionId:any;
  subCategoryData:any;
  spendingGraphData:any;
  transactionMonth:any;
  selectedTransactionMonth:any;
  pieChartColor:any;
  transactionMonthVisual:any
  selectedTransactionMonthVisual:any,
  logoImg1AddCash: string
  dataValue: Object
  dataValueNew: Object
  newCss:Object,
  bankOptions: BankOption[],
  currentPageForTransaction: number,
  hasMore: boolean,
  loader: boolean,
  selectedmonth:string|null,
  selectedyear:string|null,
  start_date:string|null,
  end_date:string|null,
  selectedCurrentBank: {name: string,
    logo_url: string,
    id: string}[],
    activeDot: { x: number | null, y: number | null },
    isHovered: boolean
    isBankListLoading: boolean;
    comesFromGoal:boolean,
      goalBanksIds:any,
      transactionStatus: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  // Customizable Area Start
  getTransactionDetailsCallId:any;
  deleteTransactionCallId:any;
  getBanksCallId: any;
  getSpendingsCallId: any;
  getRecentTransactionsCallId:any;
  getCurrencyListCallId:any;
  categoriesList:any;
  selectedCategoryId:any;
  setNewCurrencyCallId:any;
  getAllTransactionsData:any;
  getAllTransactionsDataPagination:any;
  categoriesCallId:any;
  getSubCategoryCallId:any;
  updateSubCategoryOfTransactionCallId:any;
  spendingGraphDataCallId:any;
  transactionMonthCallId:any;
  visualTransactionMonthCallId:any
  // Customizable Area End 

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      TypeFilter:localStorage.getItem('type'),
      transactionMonth:'',
      transactionMonthVisual:'',
      selectedTransactionMonth:0,
      selectedTransactionMonthVisual:0,
      subCategoryData:'',
      transactionDetail:false,
      spendingCategoryDetail:false,
      sub_category_id:'',
      transactionId:'',
      openDetailsInfo:false,
      singleTransactionDetails:'',
      singleTransactionDetailsDate:'',
      updatedSubCategoryForSingleTransacion:'',
      updatedSubCategoryForSingleTransacionId:'',
      DateFilter:localStorage.getItem('date'),
      CategoryFilter:localStorage.getItem('category'),
      TypeFilterTemp:localStorage.getItem('type') ? localStorage.getItem('type')?.split(',') :[],
      categoriesList:'',
      datesList:['1 month','Last Week','Today'],
      typesList:['Income','Expense'],
      DateFilterTemp:localStorage.getItem('date') ? localStorage.getItem('date')?.split(',') :[],
      CategoryFilterTemp:localStorage.getItem('category') ? localStorage.getItem('category')?.split(',') :[],
      recentTransactions:'',
      filterTransactions:'',
      filterTransactionsMonth:'',
      filterTransactionsData:[],
      oprand:'',
      currency_symbol:'',
      deleteModel:false,
      lmec:'',
      spendingGraphData:'',
      dialogCss:{
        width: 'max-content',
        backgroundColor: '#3c3c51',
        borderRadius: '12px',
        border: '1px solid #57575d',
        padding: '19px 23px',
    },
      graph_month:'',
      graph_data:'',
      currentPage:1,
      dropdownVisiblity:'state-dropdown',
      dropdownVisiblity2:'state-dropdown',
      spendingCategory:'',
      allSpendingCategory:'',
      currentMonthExpanse:'0.0',
      currencyList:'',
      selectedCurrency:'$USD',
      authToken:localStorage.getItem('authToken'),
      bankdropdownVisiblity:'bank-select-block',
      selectedBankName:this.getStringVisualAnalytics("Selectyourbank"),
      selectedBankIds:[],
      banks:'',
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      pieChartColor: [
        "hsl(257, 97%, 59%)",
        "hsl(264, 80%, 38%)",
        "hsl(263, 78%, 42%)",
        "hsl(262, 85%, 46%)",
        "hsl(261, 80%, 50%)",
        "hsl(260, 78%, 55%)",
        "hsl(258, 97%, 55%)",
        "hsl(259, 85%, 60%)",
        "hsl(258, 97%, 63%)",
        "hsl(257, 90%, 67%)",
        "hsl(256, 87%, 71%)",
        "hsl(255, 80%, 76%)",
        "hsl(254, 83%, 81%)",
        "hsl(253, 85%, 86%)",
        "hsl(252, 87%, 91%)",
        "hsl(251, 90%, 95%)",
        "hsl(250, 93%, 98%)"
    ],
      logoImg1AddCash: "",
      dataValue: {
        display: "flex", flexDirection: 'row-reverse',justifyContent: "flex-end",width:"100%"
      },
      dataValueNew: {
        display: "flex", flexDirection: 'row',width:"100%"
      },
      newCss:{display: "flex", justifyContent:'space-between'},
      bankOptions:[],
      currentPageForTransaction: 1,
      hasMore: true,
      loader:false,
      selectedyear:null,
      selectedmonth:null,
      start_date:null,
      end_date:null,
      selectedCurrentBank:[],
      activeDot: { x: null, y: null },
      isHovered: false,
      isBankListLoading:false,
      comesFromGoal:false,
      goalBanksIds:[],
      transactionStatus: 'pending'
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== null &&
      this.apiGetDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiGetDataCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     this.shortCode(responseJson)
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    

    this.shortCode2(responseJson2, apiRequestCallId, message)
    this.categoriesResponse(apiRequestCallId,responseJson2)
    this.manageTransactionDetailsResponse(apiRequestCallId,responseJson2, message)
    this.manageFilterTransactionResponse(responseJson2, apiRequestCallId, message)
    this.manageSubCategoryResponse(apiRequestCallId,responseJson2)
    if (responseJson2 &&
      apiRequestCallId === this.getCurrencyListCallId
    ) {
      const responseJson6 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      for(let x of responseJson6.list){
        
        if(x.id == responseJson6.selection){
          this.setState({selectedCurrency:x.symbol+x.currency_type})
        }
      }
      this.setState({ currencyList: responseJson6 })
    }
    this.handleNavigationMessage(message)

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const localCategoryFilter = await getStorageData('category')
    const recruiterId = this.props.navigation.getParam("navigationBarTitleText")
    let dateFilterValue = this.state.DateFilter;

    if (recruiterId) {
      const decodedRecruiterId = decodeURIComponent(recruiterId);
      const isNew = decodedRecruiterId.split('&&')[3] == "new";
      if (isNew) {
        localStorage.removeItem('date');
        dateFilterValue = null
      } else {
        dateFilterValue = this.state.DateFilter;
      }
    }
    
    this.setState({
      start_date: recruiterId ? decodeURIComponent(recruiterId).split('&&')[0] : null,
      end_date: recruiterId ? decodeURIComponent(recruiterId).split('&&')[1] : null,
      CategoryFilter: recruiterId ? decodeURIComponent(recruiterId).split('&&')[2] : localCategoryFilter,
      TypeFilter: recruiterId ? 'Expense': localStorage.getItem('type'),
      DateFilter: dateFilterValue,
    })
    
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImg1AddCash: logoImg1AddCash ,
      datesList:logoImg1AddCash === "rtl" ? configJSON.labelBodyTextArabic : this.state.datesList,
      typesList:logoImg1AddCash === "rtl" ? configJSON.lableTextArabic : this.state.typesList});
    this.getBanks();
    this.categories();
    this.allTransactions();
    this.getCurrencyList();
    this.functionExpireToken()
    this.setInitialPointToToday()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  getStringVisualAnalytics = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListForecasting[languageLogin][key]
  }


returnTrueOrNull = (inpData: string) => inpData ? inpData : null 

  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      const data1 = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if (data1) {
          this.setState({
           selectedmonth:  this.returnTrueOrNull(data1.selectedmonth),   
           selectedyear: this.returnTrueOrNull(data1.selectedyear),
           start_date: this.returnTrueOrNull(data1.start_date),
          end_date: this.returnTrueOrNull(data1.end_date),
          CategoryFilter:data1.sub_category?.id,
          goalBanksIds:data1.bank_account_ids,
          comesFromGoal:data1.fromGoal
          }, 
          () =>  {this.allTransactions()}
        )
          if(data1.transactionId){
            this.subCategoryGet(data1.transactionId);
          }
      }

    }
}

navigateSpendings=(transId: string | number)=>{

  const message2 = new Message(getName(MessageEnum.NavigationMessage));
  message2.addData(getName(MessageEnum.NavigationTargetMessage), "spendingCategory");
  message2.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  const raiseMessage2: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage2.addData(getName(MessageEnum.NavigationPayLoadMessage), { selectedyear:this.state.transactionMonth[this.state.selectedTransactionMonth]?.split(' ')[1],
    selectedmonth:this.state.transactionMonth[this.state.selectedTransactionMonth]?.split(' ')[0],
    transactionId: transId
    })
  message2.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage2);
  this.send(message2)

}

getMonth=()=>{
  if (this.state.selectedmonth===null && this.state.selectedyear===null){
    return(<h5 data-test-id="filterTransactionsMonth">{this.state.filterTransactionsMonth}</h5>)
  }else{
    return(<h5 data-test-id="selectedMonthYear">{this.state.selectedmonth}   {this.state.selectedyear}</h5>)
  }
}
getIndex=()=>{
  const currentPath = window.location.pathname;
  if (currentPath === '/spendingCategory'&& this.state.selectedyear!=null && this.state.selectedmonth!==null) {
    const { selectedyear, selectedmonth, transactionMonth } = this.state;
    const index = transactionMonth?.findIndex((month:any) => {
      const [monthName, year] = month.split(' ');
      return year === selectedyear && monthName === selectedmonth;
    });
    this.setState({selectedTransactionMonth:index})
  }
}
  deleteTransaction = async ()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTransactionCallId = requestMessage.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/${this.state.transactionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getTransactionMonth = ()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.transactionMonthCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_budgetingforecasting/graph_charts/transaction_months"
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  spendingGraphicalData = async ()=>{
  const bankAccountIds = await storage.get("bank_account_ids") || "";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.spendingGraphDataCallId = requestMessage.messageId;
    let newData = this.state.transactionMonth[this.state.selectedTransactionMonth]
    if(newData){
      let [month, year] = newData?.split(' ');
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        JSON.parse(bankAccountIds).length ?
        `bx_block_budgetingforecasting/graph_charts/spendings_in_chart?bank_account_id=[${JSON.parse(bankAccountIds)}]&month=${month}&year=${year}` 
        :
        `bx_block_budgetingforecasting/graph_charts/spendings_in_chart?month=${month}&year=${year}` 

      );
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ManageOprandClass = (data:any)=>{
      if(data == '+'){
        return "plus";
      }
      else{
        return "";
      }
  }
  ManageOprandClass2 = (data: any) => {
    return data == '+' ? "up" : "down";
  }


manageSubCategoryResponse = async(apiRequestCallId:any,responseJson2:any)=>{
  if(responseJson2 && apiRequestCallId == this.getSubCategoryCallId){
    this.setState({subCategoryData:responseJson2, spendingCategoryDetail:true})
  }

  if(responseJson2 && apiRequestCallId == this.spendingGraphDataCallId){
    this.setState({spendingGraphData:responseJson2}) 
  }

  if(responseJson2 && apiRequestCallId == this.updateSubCategoryOfTransactionCallId){
    toast.success(responseJson2.message)
  }
}
handleCategoryChange = (event:any) => {
  this.setState({updatedSubCategoryForSingleTransacionId:event.target.value})
  console.log(event.target.value,"drop value");
};
  manageTransactionDetailsResponse = async (apiRequestCallId:any,responseJson2:any, message:Message)=>{
    if(responseJson2 && apiRequestCallId == this.getTransactionDetailsCallId)
    {
      let onlyDate = responseJson2.date.slice(0,2)
      let withoutDate = responseJson2.date.slice(3)
      let finalDate = onlyDate+this.getFloorAppend(onlyDate)+' '+withoutDate      
      this.setState({singleTransactionDetailsDate:finalDate,singleTransactionDetails:responseJson2,updatedSubCategoryForSingleTransacion:responseJson2.sub_category.name,updatedSubCategoryForSingleTransacionId:responseJson2.sub_category.id, transactionDetail:true })
    }
    if(responseJson2 && apiRequestCallId == this.transactionMonthCallId)
    {
      this.setState({transactionMonth:responseJson2.Months, transactionStatus: responseJson2?.transaction_status},() => {
        this.getIndex()
      })
     
      this.spendingGraphicalData()
    }
    if(responseJson2 && apiRequestCallId == this.transactionMonthCallId )
    { 
      const resFromTransaction = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getAllSpendings()
    }
    if(responseJson2 && this.deleteTransactionCallId == apiRequestCallId){
      if(responseJson2.message){
        this.onCancel()
        this.setState({openDetailsInfo:false})
        toast.success("Transaction Deleted Successfully.")
        this.allTransactions()
      }
    }
  } 
  changeValueofSubCategoryOfTransation = async (value:any)=>{
    this.setState({updatedSubCategoryForSingleTransacion:value.name, updatedSubCategoryForSingleTransacionId:value.id, dropdownVisiblity2: 'state-dropdown'})
  }
  updateSubCategoryOfTransaction = async ()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateSubCategoryOfTransactionCallId = requestMessage.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/${this.state.transactionId}?sub_category_id=${this.state.updatedSubCategoryForSingleTransacionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  subCategoryGet = async(value:any)=>{
    this.setState({sub_category_id:value});
    setTimeout(()=>{
      this.subCategoryGetAPI()
    })
  }
  subCategoryGetAPI = async()=>{
    const bankAccountIds = await storage.get("bank_account_ids") || "";
    const renderBankId = JSON.parse(bankAccountIds).length ? `bank_account_id=[${JSON.parse(bankAccountIds)}]`:''
    const header = {
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoryCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let date = this.state.transactionMonth[this.state.selectedTransactionMonth] ?? `${this.state.selectedmonth} ${this.state.selectedyear}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_budgetingforecasting/graph_charts/all_transaction?${renderBankId}&sub_category_id=${this.state.sub_category_id}&date=${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  transactionDetailAPI = async (id:any)=>{
    const language = await getStorageData('language');

    this.setState({transactionId:id})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTransactionDetailsCallId = requestMessage.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/bank_account_transactions/${id}?language=${language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  seeRecentTransactions = async ()=>{
    localStorage.removeItem('type');
    localStorage.removeItem('category');
    localStorage.removeItem('date');
  }
  categoriesResponse = async (apiRequestCallId:any, responseJson:any)=>{
    if(responseJson && apiRequestCallId == this.categoriesCallId){
      this.setState({categoriesList:responseJson.data.sort((a:{attributes:{name:string}}, b:{attributes:{name:string}}) => a.attributes.name.localeCompare(b.attributes.name))  })
    }
  }
  categories = async ()=>{
    const requestMessageData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.categoriesCallId = requestMessageData.messageId;
    requestMessageData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/sub_categories/?name=spendingcategories"
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageData.id, requestMessageData);
  }
  saveFilter = async ()=>{
    localStorage.removeItem("category");
localStorage.removeItem("date");
localStorage.removeItem("type");
    this.setState({CategoryFilter:this.state.CategoryFilterTemp, 
      TypeFilter:this.state.TypeFilterTemp, 
      DateFilter:this.state.DateFilterTemp})
      localStorage.setItem("category", this.state.CategoryFilterTemp)
      localStorage.setItem("date", this.state.DateFilterTemp[this.state.DateFilterTemp.length - 1])
      localStorage.setItem("type", this.state.TypeFilterTemp)
      this.setState({CategoryFilterTemp:[],TypeFilterTemp:[],DateFilterTemp:[]})
      this.setState({CategoryFilter:[],TypeFilter:[],DateFilter:[]})

      this.gotoSomePage('Transactions')
  }
  filterCheck = async (name:any, value:any)=>{
    localStorage.removeItem('category');
localStorage.removeItem('date');
localStorage.removeItem('type');
    if(name == 'type'){
      const index = this.state.TypeFilterTemp.indexOf(value);

      if (index !== -1) {
        const updatedState = [...this.state.TypeFilterTemp.slice(0, index), ...this.state.TypeFilterTemp.slice(index + 1)];
        this.setState({ TypeFilterTemp: updatedState });
      } else {
        this.setState(prevState => ({
          TypeFilterTemp: [...prevState.TypeFilterTemp, value],
        }));
      }
    }

    if(name == 'date'){
      this.setState({
        DateFilterTemp: [value.toLowerCase()],
        DateFilter: [value.toLowerCase()],
      });
    }

    if(name == 'category'){
      if (this.state.CategoryFilterTemp.includes(value.toString())) {
        this.setState({
          CategoryFilterTemp: this.state.CategoryFilterTemp.filter((item:any) => item !== value.toString())
        });
      } else {
        this.setState({
          CategoryFilterTemp: [...this.state.CategoryFilterTemp, value]
        });
      }
    }
  }
  allTransactions = async ()=>{
    let category, date, type;
    category = this.state.CategoryFilter ? this.state.CategoryFilter :  '';
    date = this.state.DateFilter ? this.state.DateFilter : '';
    type = this.state.TypeFilter ? this.state.TypeFilter : '';

    let bankAccountIds;
    // istanbul ignore next
    if (this.state.comesFromGoal) {
      bankAccountIds = this.state.goalBanksIds;
    }else{
      let bankIds = await storage.get("bank_account_ids") || "";
      bankAccountIds = JSON.parse(bankIds);
    }

     const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllTransactionsDataPagination = requestMessage.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.logoImg1AddCash === "rtl" ? configJSON.apiNewreact :
      this.getparams(bankAccountIds)
      
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.setState({
      loader: true
    })

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  checkForNoFilter = () => this.state.selectedyear === null && this.state.selectedmonth === null && this.state.start_date === null && this.state.end_date === null;
  checkForYearMonth = () => this.state.selectedyear != null && this.state.selectedmonth != null && this.state.start_date === null && this.state.end_date === null;
  checkForStartEndDate = () => this.state.selectedyear == null && this.state.selectedmonth == null&&this.state.start_date!=null &&this.state.end_date!=null;
  checkType =(types: string)=> types.length ? `&type=${types.toLowerCase()}`:''  
  checkBankAccount =(bankAccountIds: number[])=>  bankAccountIds.length ? `bank_account_id=[${bankAccountIds}]`:''

  getparams=(bankAccountIds: number[])=>{    
    let category, date, type, categoryName, dateName;
    category = this.state.CategoryFilter ? this.state.CategoryFilter :  '';
    date = (this.state.DateFilter != undefined && this.state.DateFilter != 'undefined') ? this.state.DateFilter : '';
    type = this.state.TypeFilter ? this.state.TypeFilter : '';    

    categoryName = category.length ? `&category=${category}` : ''
    dateName = date.length?`&date=${date}`:''

      if( this.checkForNoFilter()){
        return `bx_block_budgetingforecasting/graph_charts/filter_transaction?${this.checkBankAccount(bankAccountIds)}${categoryName}${dateName}${this.checkType(type)}&page=${this.state.currentPageForTransaction}`;
      }else if (this.checkForYearMonth()){
        return`bx_block_budgetingforecasting/graph_charts/filter_transaction?bank_account_id=[${bankAccountIds}]&category=${category}&date=${date}&type=${type}&month=${this.state.selectedmonth}&year=${this.state.selectedyear}&page=${this.state.currentPageForTransaction}`;
      }else if (this.checkForStartEndDate()){
        return`bx_block_budgetingforecasting/graph_charts/filter_transaction?${this.checkBankAccount(bankAccountIds)}&category=${category}&start_date=${this.state.start_date}&end_date=${this.state.end_date}${dateName}&type=expense&page=${this.state.currentPageForTransaction}`;
      }
  
  }

  gotoTransactions=()=>{
    this.gotoSomePage('Transactions');
  }

  gotoSomePage=(pageName: string)=>{
    if (!!this.state.start_date && !!this.state.end_date) {
      localStorage.setItem('type', 'Expense' )
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${this.state.start_date}&&${this.state.end_date}&&${this.state.CategoryFilter}`);
      this.send(message)

    } else {
      this.props.navigation.navigate(pageName)
    }
  }

  gotoFilters = () => {
    this.gotoSomePage('Filters')
  }

  shortCode = async (responseJson:any) =>{
    if (responseJson && responseJson.data) {
      let webData: [any] = [["Title", "Value"]];
      for (
        var iIndex = 0;
        iIndex < responseJson.data.labels.length;
        ++iIndex
      ) {
        let label = responseJson.data.labels[iIndex];
        let value = responseJson.data.data[iIndex][0];
        webData.push([label, value]);
      }
      this.setState({ info: responseJson.data, googleChartData: webData });
    }
  }
  updateMonth = async (direction:string)=>{
    if(direction == 'minus'){
      if(this.state.selectedTransactionMonth < this.state.transactionMonth.length-1){
        this.setState({selectedTransactionMonth:this.state.selectedTransactionMonth+1})

      }
    }
    else{
      if(this.state.selectedTransactionMonth > 0){
        this.setState({selectedTransactionMonth:this.state.selectedTransactionMonth-1})
      }
    }
    setTimeout(()=>{
      this.spendingGraphicalData()
      this.getAllSpendings();
    },100)
  }
  shortCode2 = async (responseJson2:any, apiRequestCallId:any, message: Message)=>{
    if (responseJson2 &&
      apiRequestCallId === this.getBanksCallId
    ) {
      const bankListApiResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({bankOptions:bankListApiResponseJson.Banks,isBankListLoading:false})      
      this.getRecentTransactions();
      this.spendingGraphicalData();
      this.getSpendings()
    }

    if (responseJson2 &&
      apiRequestCallId === this.getSpendingsCallId
    ) {
      const responseJson4 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.spendingResponse(responseJson4);
     
    }

    if(apiRequestCallId === this.getRecentTransactionsCallId && responseJson2 ){
      const responseJson5 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.recentTransactionsResponse(responseJson5)
    }
    if(responseJson2 && apiRequestCallId === this.setNewCurrencyCallId){
      const responseJson7 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson7){
        this.getRecentTransactions();
        this.getSpendings();
        this.spendingGraphicalData()
        if(this.state.spendingCategoryDetail){
          this.subCategoryGetAPI()
        }
        this.setState({
          currentPageForTransaction: 1,
          filterTransactionsData: [],
          hasMore: true
        },()=>{
          this.allTransactions();
        })
      }
    }
  }

  manageFilterTransactionResponse = async (responseJson2:any, apiRequestCallId:any, message: Message)=>{
    if(responseJson2 &&
      apiRequestCallId === this.getAllTransactionsDataPagination){
        this.setState({filterTransactionsData:[...this.state.filterTransactionsData,...responseJson2.transaction.data],
          hasMore: responseJson2.transaction.data.length === 0 ?false : true,
          loader: false,
           filterTransactions:responseJson2, filterTransactionsMonth:responseJson2.month,currentPageForTransaction: this.state.currentPageForTransaction +1})
      
      }

  }

  getFilteredData = (): GraphDataEntry[] => {
    const today = new Date();
    const currentMonth = today.toLocaleString('default', { month: 'short' });
    const currentDate = today.getDate();
  
    return this.state.graph_data.map((entry: GraphDataEntry)  => {
      const [entryMonth, entryDay] = entry.month.split(" ");
      const dayNumber = parseInt(entryDay, 10);
      // istanbul ignore next
      if (entryMonth === currentMonth && dayNumber > currentDate) {
        return {
          ...entry,
          value: null,
          formattedValue: "null"
        };
      }
      return entry;
    });
  };
  

  selectCurrency = async (value:any) => {
    this.setState({selectedCurrency:value.symbol+value.currency_type})
    const formData = new FormData();
    formData.append("currency_id", value.id);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setNewCurrencyCallId = requestMessage.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_currency_to_account"
    );
    requestMessage.addData
    (getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  spendingResponse = async (responseJson4:any)=>{
    let spending;
    let newData = responseJson4.spending_category.length;
    if(newData > 5){
       spending = responseJson4.spending_category.slice(0,5);
    }
    else{
      spending = responseJson4.spending_category;

    }
    this.setState({allSpendingCategory:responseJson4.spending_category,oprand:responseJson4.operand,lmec:responseJson4.last_month_expense_comparison,currency_symbol:responseJson4.currency_symbol, currentMonthExpanse: responseJson4.currency_symbol+responseJson4.current_month_expense, spendingCategory:spending, graph_month:responseJson4.month_acc_db})
    const convertedData = [];
    for (const [month, value] of responseJson4.graph) {
      let formattedValue = value;
      if (value >= 1000) {
        formattedValue = (value / 1000).toFixed(1) + 'k';
      }else{
        formattedValue = value.toFixed(2);
      }
      convertedData.push({ month, value, formattedValue });
    }
    this.setState({graph_data:convertedData}, ()=>{
    this.setInitialPointToToday()
    })
  }
  recentTransactionsResponse = async (response:any) => {
    if(response && response.data){
      let transactionData;
      if (response.data.length > 5) {
        transactionData = response.data.slice(0, 5);
      }
      else {
        transactionData = response.data;
      }
      this.setState({ recentTransactions: transactionData })
    }else{
      this.setState({ recentTransactions: '' })      
    }
  }
  
  getaccountnumber = (text: string) => {
    if(!text){
      return ""
    }
    let length = text.length
    let stars = ''
    for(let index = 1; index < length -6; index++){
      stars = stars + '*'
    }
    let value = text.slice(length - 6, length);
    return stars+value
  }

  getCurrencyList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrencyListCallId = requestMessage.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/currency_list"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getBanks = async () => {
    this.setState({isBankListLoading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getBanksCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/dashboards/connected_banks"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFloorAppend(floorNumber: number) {
    if (floorNumber % 100 === 11 || floorNumber % 100 === 12 || floorNumber % 100 === 13) {
      return "th";
    }

const lastDigit = floorNumber % 10;
switch (lastDigit) {
  case 1:
    return "st";
  case 2:
    return "nd";
  case 3:
    return "rd";
  default:
    return "th";
}
  }
  
  getSpendings = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.getSpendingsCallId = requestMessage.messageId;

    
    let newData = this.state.transactionMonth[this.state.selectedTransactionMonth]
    let [month, year] = newData.split(' ');
    if (this.state.selectedBankIds.length > 0) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_budgetingforecasting/graph_charts/web_spendings?bank_account_id=[${this.state.selectedBankIds}]&month=${month}&year=${year}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_budgetingforecasting/graph_charts/web_spendings?month=${month}&year=${year}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectBankApiCall = (selectedBankIds: number[])=>{
    this.setState({ selectedBankIds: selectedBankIds },()=>{
      this.getRecentTransactions();
      this.getSpendings();
    })
  }

  getAllSpendings = async ()=>{
    setTimeout(()=>{
      this.getSpendings();
    },100)
  }
  getRecentTransactions = async () => {
    const bankAccountIds = await storage.get("bank_account_ids") || "";
    const bankAccountIdsJson = JSON.parse(bankAccountIds);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.getRecentTransactionsCallId = requestMessage.messageId;
    let bankIds
    // istanbul ignore next
    if (this.state.selectedBankIds.length > 0) {
      bankIds = this.state.selectedBankIds
    } else if (bankAccountIdsJson.length > 0) {
      bankIds = bankAccountIdsJson
    } else {
      bankIds = []
    }
    if (this.state.selectedBankIds.length > 0 || bankAccountIdsJson.length > 0) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.logoImg1AddCash === "rtl"?
        `${configJSON.reactNewApi}?${configJSON.bankAccId}=[${bankIds}]`:
        `${configJSON.recentTransApi}?${configJSON.bankAccId}=[${bankIds}]`
      );
    } else {
      const chartMonth = this.state.transactionMonth[this.state.selectedTransactionMonth].split(" ")[0]
      const chartYear = this.state.transactionMonth[this.state.selectedTransactionMonth].split(" ")[1]
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.logoImg1AddCash === "rtl" ? configJSON.reactNewApi :
          `bx_block_budgetingforecasting/graph_charts/recent_transactions?[date][month]=${chartMonth}&[date[[year]=${chartYear}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  bankdropdownVisiblityChange = () => {
    if (this.state.bankdropdownVisiblity == 'bank-select-block') {
      this.setState({ bankdropdownVisiblity: 'bank-select-block show-bank' })
    }
    else {
      this.setState({ bankdropdownVisiblity: 'bank-select-block' })
    }
  }
  handleDropdown = () => {
    if (this.state.dropdownVisiblity == 'state-dropdown') {
      this.setState({ dropdownVisiblity: 'state-dropdown active' })
    } else {
      this.setState({ dropdownVisiblity: 'state-dropdown' })

    }
  }
  handleDropdown2 = () => {
    if (this.state.dropdownVisiblity2 == 'state-dropdown') {
      this.setState({ dropdownVisiblity2: 'state-dropdown active' })
    } else {
      this.setState({ dropdownVisiblity2: 'state-dropdown' })

    }
  }
  handleCategoryDropDown=()=>{
if(this.state.dropdownVisiblity2 == 'state-dropdown active'){
  this.setState({dropdownVisiblity2: 'state-dropdown' })
}
  }
  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  dialogModel = () => {
    this.setState({ deleteModel: !this.state.deleteModel })
  }
  onCancel = () => {
    this.setState({ deleteModel: !this.state.deleteModel })
}



updateMonthVisual = async (direction:any)=>{
  if(direction == 'plus'){
    if(this.state.selectedTransactionMonthVisual < this.state.transactionMonthVisual.length-1){
      this.setState({selectedTransactionMonthVisual:this.state.selectedTransactionMonthVisual+1})
    }else{
      this.setState({selectedTransactionMonthVisual:0})
    }
  }
  else{
    if(this.state.selectedTransactionMonthVisual > 0){
      this.setState({selectedTransactionMonthVisual:this.state.selectedTransactionMonthVisual-1})
    }else{
      this.setState({selectedTransactionMonthVisual:this.state.transactionMonthVisual.length-1})
    }
  }
  setTimeout(()=>{
    this.spendingGraphicalDataVisual()
  },100)
}


spendingGraphicalDataVisual = async ()=>{
  const bankAccountIds = await storage.get("bank_account_ids") || "";
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const headers = {
    token: this.state.authToken
  };
  this.spendingGraphDataCallId = requestMessage.messageId;
  let newData = this.state.transactionMonthVisual[this.state.selectedTransactionMonthVisual]
  if (newData) {
    let [month, year] = newData.split(' ');
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_budgetingforecasting/graph_charts/spendings_in_chart?bank_account_id=[${JSON.parse(bankAccountIds)}]&month=${month}&year=${year}`
    );
  }
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getStyles: Styles<OptionsType<{
  value: string
}>, boolean> = {
  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "#395D6B",
    borderRadius: '8px',
    color: 'rgba(255, 255, 255, 0.60)',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textAlign: "left",
    fontWeight: 500,
    border: "1px solid  rgba(255, 255, 255, 0.60)",
    padding: "8px"

  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused || isSelected ? "#2ce2d5 !important" : "#395D6B !important",
      color: isFocused || isSelected ? "#000 !important" : "#fff !important",
      padding: "8px"
    };
  },
  menuList: base => ({
    ...base,
    margin: 0,
  }),
}
handleGlobaldropdown=()=>{
  if(this.state.dropdownVisiblity== 'state-dropdown active'){
    this.setState({ dropdownVisiblity: 'state-dropdown' }) }
}
functionExpireToken=async()=>{
    const authToken = localStorage.getItem("token")
  if (!authToken ) {
    this.NavigateToLogout() 
}
}
NavigateToLogout=()=>{
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('authToken');
  window.location.href = '/EmailAccountLogin';
}


  setInitialPointToToday = () => {
    const { graph_data } = this.state;
    const today = new Date();
    const formattedToday = today.toLocaleString('default', { month: 'short', day: 'numeric' });

    const todayPoint = graph_data.find((point: { formattedValue: string, month: string, value: number | null }) => point.month === formattedToday);
    if (todayPoint) {
      this.setState({
        activeDot: { x: todayPoint.month, y: todayPoint.value },
      });
    }
  };

  handleMouseMove = (e: RechartsMouseEvent) => {
    if (e && e.activeLabel) {
      const hoveredPoint = this.state.graph_data.find((point: { formattedValue: string, month: string, value: number }) => point.month === e.activeLabel);
      if (hoveredPoint) {
        this.setState({
          activeDot: { x: hoveredPoint.month, y: hoveredPoint.value },
          isHovered: true,
        });
      }
    }
  };

  handleMouseLeave = () => {
    this.setState({ activeDot: { x: null, y: null } })
  };

  // Customizable Area End
}
