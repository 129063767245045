import React from "react";
// Customizable Area Start
import "./style.css";
import {
  Box, Typography,Popover,
   Grid,
} from "@material-ui/core";
import {
  contactus1,
  star,
  contactus3,
  subarrowicon,
  videoCall,
  picture,
  arrow5,
  bell,
  right,
  crosss
} from "./assets";

import { styled, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import moment from "moment";


// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import DashboardCardOverview from "../../../components/src/DashboardCardOverview";
import ClientFilter from "../../../components/src/ClientFilter";
import ClientList from "../../../components/src/ClientList";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start






  handleDropdownLineChange = (event: any) => {
    this.setState({ selectedOptionLine: event.target.value });
  };
  handleDropdownChange = (event: any) => {
    this.setState({ selectedOption: event.target.value });
  };

  formatValue = (value: any) => {
    if (value === 0) return "500";
    if (value === 20) return "1k";
    if (value === 40) return "2k";
    if (value === 60) return "5k";
    if (value === 80) return "10k";
    if (value === 100) return "25k";
    return "";
  };
getConsultationCall=(data:any)=>{
  return(
    <>
    <div style={{display:'flex' , justifyContent:'space-between',alignContent:"center",alignItems:"center"}}>

      <div
        style={{
          color: '#8C8C90',
          display: "flex",
          fontSize: '14px',
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems:"center"
        }}
      >
        

        <div >
          <img src={videoCall} alt="contactus3" style={{ width: '30px', objectFit: 'cover', height: 30, marginTop: '16px' }} />
        </div>

        <div style={{ color: 'white',paddingLeft:"10px"  }}>
          <p style={{ marginBottom :'0px', fontSize: '14px',}}><b>{data?.user || 'Client Name'}</b></p>
          <span style={{ marginTop: '-12px', color: '#ffffff78' }} data-test-id="timeSlot"> {this.displayTimeConverter(data.starts_at as string, data.ends_at as string)}</span>     
        </div>
      
      </div>
        <div >
        <CallJoinBtn1
              style={this.getJoinButtonStyle(
                data?.call_join, 
                styles.ActiveCallJoinBtn1, 
                styles.defaultButton
              )}
              onClick={()=>{this.VideoCallClient(data)}}
  
            >
              Join call
            </CallJoinBtn1>    
        </div>
    </div>
    </>
  )
}
getPendingData=(data:any)=>{
  return (
    <div
      style={{
        gap: "6%",
        color: '#8C8C90',
        display: "flex",
        flexDirection: "row",
        fontSize: '14px',
        justifyContent: "flex-start",
        marginTop:'5px'
      }}
    >
      <div >
        <img src={bell} alt="bell"
          style={{
            height: 30,
            objectFit: 'cover',
            width: '30px',
            marginTop: '16px'
          }} />

      </div>
      <div style={{ color: 'white' }}>
        <p style={{ display: 'grid' }}>
          <span style={{ fontSize: '14px', color: '#ffffff78', marginTop: '-4px', }}>You got an invitation request from</span>

          <p style={{margin:'0px'}}>
            <span style={{ color: 'white', fontSize: '14px', }}><b>{data?.user || 'Client Name'}</b></span>
            <span style={{ color: '#ffffff78', fontSize: '14px', marginLeft: '20px' }}>
              {moment.utc(data?.time, "MMMM DD, YYYY [at] HH:mm A").local().format("MMMM DD, YYYY [at] hh:mm A")}
              </span>
          </p>
        </p>
        <div style={{ display: 'flex', flexWrap:'wrap', gap: '23%', marginTop: '14px' }} className="pending-request-div">
          <p style={{
            color: '#C9F9F6',
            paddingRight: '12px',
            paddingLeft: '12px',
            border: '1px solid #C9F9F6',
            fontSize: '16px',
            borderRadius: '7px',
            textAlign:'center',
            marginBottom:'10px',
            cursor: 'pointer',
          }}data-test-id="userAcceptDeclineClient" onClick={()=>{this.userAcceptDecline(data?.user_id.toString(), 'rejected')}}><b>Decline</b></p>
          <p style={{
            color: 'black',
            paddingLeft: '12px',
            fontSize: '16px',
            paddingRight: '12px',
            borderRadius: '7px',
            border: '1px solid #C9F9F6',
            backgroundColor: '#C9F9F6',
            textAlign:'center',
            marginBottom:'10px',
            cursor: 'pointer',
          }} data-test-id="userAcceptDeclineClient" onClick={() => this.userAcceptDecline(data?.user_id.toString(), 'accepted')}><b>Accept</b></p>
        </div>

      </div>

    </div>
  )
}
getAcceptedData=(data:any)=>{
  return (
    <div
            style={{
              flexDirection: "row",
              display: "flex",
              gap: "6%",
              justifyContent: "flex-start",
              fontSize: '14px',
              color: '#8C8C90',
              marginBottom:'5px'
            }}
          >
            <div >
              <img src={right} alt="right"
                style={{
                  objectFit: 'cover',
                  width: '30px',
                  marginTop: '16px',
                  height: 30,
                  minWidth:'30px'
                }} />

            </div>
            <div style={{ color: 'white' }}>
              <p style={{ display: 'grid' }}>
                <span style={{ color: '#ffffff78', fontSize: '14px', marginTop: '-4px' }}>You accepted invitation from</span>

                <p style={{margin :'0px'}}>
                  <span style={{ color: 'white', fontSize: '14px', 
                  wordBreak:'break-all',
                  marginRight:'20px'
                  }}><b>{data?.user || 'Client Name'}</b></span>
                  <span style={{ color: '#ffffff78', fontSize: '14px' }}>{data?.time ? moment.utc(data?.time, "MMMM DD, YYYY [at] HH:mm A").local().format("MMMM DD, YYYY [at] hh:mm A") : ""}</span>
                </p>
              </p>
              <div style={{ gap: '23%', display: 'flex', marginTop: '14px',marginLeft:'' }}>
                <p 
                  style={{
                    textAlign:'center',
                    fontSize: '16px',
                    borderRadius: '7px',
                    border: '1px solid #C9F9F6',
                    color: '#C9F9F6',
                    width:'100%',
                    maxWidth:'200px'
                  }}
                  onClick={() => this.navigateRoute2("Messages")}
                  data-test-id="chat-btn"
                ><b>Chat</b></p>

              </div>

            </div>

          </div>
  )
}
  ActivityFunction = (data: any) => {


    if (data.filter==='Consultation calls' ) {
      return (
        <>
               {this.getConsultationCall(data)}
        </>
      )
    }
    if (data.filter==='Pending Request') {
      return (
       <>
       {this.getPendingData(data)}
       </>
      )
    }
    if (data.filter==='Accepted Request' ) {
      return (
        <>
        {this.getAcceptedData(data)}
        </>
      )
    }
    if (data.filter==='All' ) { 
      if( data?.call_join === false ||data?.call_join === true) {
        return (
          <>
               {this.getConsultationCall(data)}
        </>
        )
      }
      if (data?.status === 'pending') {
        return (
         <>
         {this.getPendingData(data)}
         </>
        )
      }
      if (data?.status === 'accepted' ) {
        return (
          <>
          {this.getAcceptedData(data)}
          </>
        )
      }
      if (data?.status === 'rejected' ) {
      return (
        <div
        style={{
          color: '#8C8C90',
          gap: "6%",
          fontSize: '14px',
          justifyContent: "flex-start",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div >
          <img src={crosss} alt="crosss"
            style={{
              marginTop: '16px',
              width: '30px',
              objectFit: 'cover',
              height: 30,
            }} />

        </div>
        <div style={{ color: 'white' }}>
          <p style={{ display: 'grid' }}>
            <span style={{ marginTop: '-4px', fontSize: '14px', color: '#ffffff78', }}>You declined inviation from</span>

            <p style={{margin:'0px'}}>
              <span style={{ fontSize: '14px', color: 'white' }}><b>{data?.user || 'Client Name'}</b></span>
              <span style={{ fontSize: '14px', color: '#ffffff78', marginLeft: '20px' }}>{moment.utc(data?.time, "MMMM DD, YYYY [at] HH:mm A").local().format("MMMM DD, YYYY [at] hh:mm A")}</span>
            </p>
          </p>

        </div>

      </div>
      )
    }
    }
  }

  // Customizable Area End

  render() {
    const { getClientActivityData} = this.state;

    // Customizable Area Start
    
    return (
      <div className="pagedashboard-wrapper2">

        <div className="header-wrapper" style={{ justifyContent: 'end' }}>

          <div className="page-info">
            <ul className="drop-menu">
              <li>
              <div data-test-id="profileLink" style={styles.container} onClick={()=> this.goToProfilePage()}>
                  <div>
                    {this.state.plannerInfo.image ? (
                      <img
                        src={this.state.plannerInfo.image}
                        alt="profile"
                        style={styles.image}
                      />
                    ) : (
                      <div style={styles.noImg}>
                        {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
                      </div>
                    )}
                  </div>
                  <div style={styles.textContainer}>
                    <span style={styles.name}>{this.state.plannerInfo.name}</span>
                    <span style={styles.email}>{this.state.plannerInfo.email}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="client-wrapper">
          <div className="client-left-table-wrapper">
          {
              this.state.plannerServiceDetails &&
              <Grid container spacing={4} style={{
                marginBottom: "18px"
              }} data-test-id="planner-details">
                <Grid item md={6}  sm={12} lg={4} xs={12}>
                  <DashboardCardOverview count={this.state.plannerServiceDetails.total_client} title="Total clients" operand={this.state.plannerServiceDetails.client_operand} percentage={this.state.plannerServiceDetails.client_percent} image={contactus3} />
                </Grid>
                <Grid item md={6}  lg={4} sm={12} xs={12}>
                  <DashboardCardOverview count={this.state.plannerServiceDetails.active_services} title="Active Services" operand={this.state.plannerServiceDetails.service_operand} percentage={this.state.plannerServiceDetails.service_percent} image={contactus1} />
                </Grid>
                <Grid item md={6}  lg={4} sm={12} xs={12}>
                  <DashboardCardOverview count={this.state.plannerServiceDetails.client_satisfaction} title="Client Satisfaction" operand={this.state.plannerServiceDetails.rating_operand} percentage={this.state.plannerServiceDetails.rating_percent} image={star} />
                </Grid>
              </Grid>
            }

            <div className="faq-wrapper">
              <div
                className={this.state.expandBank}
                data-test-id="bankaccounttab"
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              >

                <div style={styles.tableWrapper1}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center"
                  }}>
                    <h3 style={styles.heading1}>Clients</h3>
                    <ClientFilter focustList={this.state.focustList} filters={this.state.filter} handleFilter={this.handleFilter} activeTab={this.state.activeTab} />

                  </div>

                  <div style={styles.clientTabContainer1}>
                    <div data-test-id="pending-service" style={this.isTabActive('pending') ? styles.activeTabStyle1 : {}} onClick={() => {
                      this.handleTabChange("pending")
                    }}>
                      <p>Pending</p>
                    </div>
                    <div data-test-id="in-progress-service" style={this.isTabActive("in_progress") ? styles.activeTabStyle1 : {}} onClick={() => {
                      this.handleTabChange("in_progress")
                    }} >
                      <p>In Progress</p>
                    </div>
                    <div data-test-id="completed-service" style={this.isTabActive('completed') ? styles.activeTabStyle1 : {}} onClick={() => {
                      this.handleTabChange("completed")
                    }}>
                      <p>Completed</p>
                    </div>
                  </div>
                  <ClientList goToClientPage={this.goToClientPage} clientServices={this.state.clientServices} activeTab={this.state.activeTab} page={this.state.currentPage} totalRecord={this.state.totalRecords} rowPerPage={7} clients={this.state.clients} handlePageChange={this.handlePageChange} />
                </div>

              </div>
            </div>

          </div>

          <div className="faq-wrapper client-time-dropdown">
            <div
              className={this.state.expandBank}
              style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              data-test-id="bankaccounttab"
            >
              <div style={{ display: 'flex', color: 'white', justifyContent: 'space-between'}}>
                <h4 style={{ color: 'white', marginTop: '0px' }}>Today</h4>
              
                <div>
            
                    <IncomeOverviewSelect className="request-select" data-test-id="income-overview-dropdown1" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleIncomeSelectClickActivty(event)}>{this.state.ActivityClientSelect}
                      <img src={arrow5} alt="morevert" style={{ width: '14px' }} />
                    </IncomeOverviewSelect>
                    <Popover
                      open={Boolean(this.state.incomeAnchorToolTipElClient)}
                      elevation={0}
                      anchorEl={this.state.incomeAnchorToolTipElClient}
                      onClose={() => this.closeIncomeOverviewPopover()}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      className="simple-tooltip"
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          marginTop: "10px"
                        }
                      }}
                    >
                      <Box style={styles.actionContainer}>
                        <Typography style={styles.monthlyIncomeTxt} data-test-id="income-overview-month-duration-1" onClick={() => this.handleIncomeOverviewDropdownActivity(this.ActivityClientSelect.ConsultationCalls)}>
                          Consultation calls
                        </Typography>
                        <Typography style={styles.yearlyIncomeTxt} data-test-id="income-overview-Accepted-duration-1" onClick={() => this.handleIncomeOverviewDropdownActivity(this.ActivityClientSelect.AcceptedRequest)}>
                          Accepted Request
                        </Typography>
                        <Typography style={styles.yearlyIncomeTxt} data-test-id="income-overview-Pending-duration-1" onClick={() => this.handleIncomeOverviewDropdownActivity(this.ActivityClientSelect.PendingRequest)}>
                          Pending Request
                        </Typography>
                        <Typography style={styles.yearlyIncomeTxt} data-test-id="income-overview-All-duration-1" onClick={() => this.handleIncomeOverviewDropdownActivity(this.ActivityClientSelect.All)}>
                          All
                        </Typography>
                      </Box>
                    </Popover>
                  </div>
              
             
                   
           
            </div>

              {
                getClientActivityData?.map((data: any) => {
                  return this.ActivityFunction(data)
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledRating = withStyles({
  iconFilled: {
    color: '#FFD700',
  },
  iconEmpty: {
    color: '#D3D3D3',
  },
})(Rating);

const styles = {
  ActiveCallJoinBtn1: {
    backgroundColor: "rgba(6, 109, 230, 0.1)",
    color: "rgb(6, 109, 230)",
    borderRadius:'5px'
  },
  defaultButton:{
    backgroundColor:'#A7AEC11A',
    color:'#A7AEC1',
    borderRadius:'5px'
  },

  expandBank: {
    border: '2px solid rgba(192, 182, 182, 0.1)',
  },
  tableWrapper1: {
    marginLeft: '3%',
    flex: 1,
  },
  heading1: {
    color: 'white',
  },
  tableRow1: {
    fontSize: '13px',
    padding: '2%',
    borderBottom: '1px solid #ffffff78',
    boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
    gap: '3%',
    justifyContent: 'flex-start',
    color: 'white',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  tableRow2: {
    flexDirection: 'row',
    gap: '3%',
    justifyContent: 'flex-start',
    boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
    color: 'white',
    alignItems: 'center',
    borderBottom: '1px solid #ffffff78',
    display: 'flex',
    padding: '2%',
    fontSize: '13px',
  },

  column: {
    flex: 1,
  },
  column1: {
    flex: 1,
  },
  actionColumn1: {
    textAlign: 'center',
  },
  actionColumn2: {
    textAlign: 'center',
  },
  pagination1: {
    alignItems: 'center',
    fontSize: '10px',
    color: '#8C8C90',
    gap: '3%',
    justifyContent: 'end',
    flexDirection: 'row',
    display: 'flex',
  },
  paginationItem1: {
    paddingRight: '15px',
    paddingBottom: '2px',
    borderRadius: '8px',
    paddingTop: '2px',
    paddingLeft: '15px',
    border: '2px solid rgb(44, 226, 213)',
  },
  actionContainer: { 
    backgroundColor: "#395d6b", 
    padding: "1rem", 
    borderRadius:"14px"
  },
  monthlyIncomeTxt: { 
    fontFamily:"Poppins", 
    fontSize:"14px", 
    color:"#fff", 
    cursor:"pointer" 
  },
  yearlyIncomeTxt: {
    fontFamily:"Poppins", 
    fontSize:"14px", 
    color:"#fff", 
    marginTop:"1rem", 
    cursor:"pointer" 
  },
  clientTabContainer1:{
    color: '#ffffff80', display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '2% 0px 0px',
    gap: '10%',
    borderBottom: '1px solid #ffffff78',
    fontSize: '13px',
    cursor:"pointer"
  } as React.CSSProperties,
  activeTabStyle1: { color: 'white' , marginBottom: -1, borderBottom: '3px solid rgb(44, 226, 213)'}as React.CSSProperties,
  noImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    backgroundColor: "2CE2D5",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
  }as React.CSSProperties,
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  }as React.CSSProperties,
  image: {
    height: "35px",
    borderRadius: "50%",
    width: "35px",
  },
  textContainer: {
    display: "flex",
    paddingLeft: "12px",
    flexDirection: "column",
    justifyContent: "center",
  }as React.CSSProperties,
  name: {
    fontSize: "14px",
    color: "white",
  },
  email: {
    fontSize: "12px",
    color: "#ffffff78",
    marginTop:"-8px"
  },

};
const IncomeOverviewSelect = styled(Box)({
  backgroundColor: 'transparent', 
  appearance: 'none', 
  color: 'white', 
  borderRadius: '5px', 
  border: '1px solid #f3ecec6e',
  paddingTop: '1px',
  paddingBottom: '1px', 
  paddingLeft: '9px', 
  paddingRight: '9px', 
  display: 'flex', 
  justifyContent: "space-between", 
  alignItems: 'center', 
  gap: '10%'
})
const CallJoinBtn1 = styled('button')({
  height: "32px",
  backgroundColor: "#A7AEC11A",
  color: "#A7AEC1",
  borderRadius: "5px",
  paddingLeft: "12px",
  paddingRight: "12px",
  outline: "none",
  border: "none"
})
// Customizable Area End
