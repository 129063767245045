import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { formatText } from "../../../components/src/ChartUtils";
import getPerformanceAssetDistribution from "../../../components/src/AssetDistributionFun.web";
type CurrencyPieChartData = {
  currency_symbol: string;
  total_networth: number;
  data: {
    [info: string]: number; 
  };
};
type AssetName = "Cash" | "Bank" | "Stocks" | "Crypto" | "Shares & Funds" | "Property" | "Luxury" | "Pension" | "Liabilities" | "Commodities" ;
type BarChartData = {
  name: AssetName;
  data: number[];
  currency:string;
  direction:string;
} & Record<AssetName, number> & {
  [key: string]: string | number
};
export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}
interface IFinancialSummary {
  total_gain: number;
  total_gain_percentage: number ;
  total_gain_percentage2?: number ;
  total_gain2?: number;
  monthly_gain: number;
  monthly_gain_percentage: number;
  yearly_gain: number;
  yearly_gain_percentage: number;
  total_investment: number;
  total_asset_count: number;
}
interface IFinancialSummary2 {
  total_gain: number;
  total_gain_percentage: number ;

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currencyPieChartData: {
    name: string;
    value: number;
  }[],
  currencyPieChartInfo:{
    currency_symbol: string;
    total_networth: number;
  },
  assetsType:string;
  allocationPercentage: number,
  allocationPieChart: {
    name: string;
    value: number;
  }[],
  activeIndex: undefined |number,
  currencyPieChartPositions: {
    index: number,
    position:number
  }[],
  allocationPieChartPositions: {
    index: number,
    position:number
  }[],
  performanceFilter: string,
  performanceData: BarChartData[],
  performanceBarData: string[],
  direction: string,
  language:string,
  activeCurrencyIndex: undefined |number,
  currentLevel:{
    level: number,
    name: string,
    previousName: string,
    realName?: string;
    nextLavel:boolean;
  },
  financialSummary: IFinancialSummary | null,
  financialSummary2: IFinancialSummary | null,
  plannerInfo: {
    name:string,
    email:string,
    image:string
  },
  hasPermission: boolean
  current_asset_id: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class ClientAssetsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    // Customizable Area End


    this.state = {
      // Customizable Area Start
      currencyPieChartData:[],
      currencyPieChartInfo:{
        currency_symbol: "",
        total_networth: 0
      },
      assetsType:"Currency",
      allocationPercentage:0,
      allocationPieChart:[],
      activeIndex:undefined,
      currencyPieChartPositions:[],
      allocationPieChartPositions:[],
      performanceFilter:"6M",
      performanceData:[],
      performanceBarData: [],
      direction:'ltr',
      language:"English",
      activeCurrencyIndex:undefined,
      currentLevel:{
        level: 1,
        name: 'Dashboard',
        previousName:"",
        realName:"personal_loans",
        nextLavel:true
      },
      financialSummary: null,
      financialSummary2: null,
      plannerInfo: {
        name:"",
        image:"",
        email:""
      },
      current_asset_id: '',
      hasPermission: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyPieChartResponse(apiRequestCallId,responseJson)
    this.handleGetCountrypieChartResponse(apiRequestCallId,responseJson);
    this.handleGetDivercificationResponse(apiRequestCallId,responseJson);
    this.handleGetAssetAllocationResponse(apiRequestCallId,responseJson)
    this.handleGetPeformanceResponse(apiRequestCallId,responseJson);
    this.handleGetPerformanceLevel2Response(apiRequestCallId,responseJson);
    this.handleGetPerformanceLevel3Response(apiRequestCallId,responseJson);
    this.handleGetSubGraphAssetAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetSubGraphIndividualAssetAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetAllAssetMatrixResponse(apiRequestCallId,responseJson);
    this.handleGetSubGraphCurrencyAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetIndividualCurrencyAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetIndividualCountryAllocationResponse(apiRequestCallId,responseJson);
    this.handleGetCountrypieChartLevel2Response(apiRequestCallId,responseJson);
    this.handleUserProfileResponse(apiRequestCallId,responseJson)
    this.handleGetAccessPermissionResponse(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAccessPermission();
    this.getDivercificationScore();
    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.handleRefreshDashboard()
    this.getUserProfile();
    this.setState({ direction: currentLanguage ,language: language});
  }


  getCurrencyPieChartAPICallId:string = ""; 
  getCountryPieChartAPICallId: string = "";
  getCountryPieChartLevel2APICallId: string = "";
  getDivercificationScoreAPICallId:string ="";
  getAllocationAPICallId:string = "";
  getPerformanceAPICallId:string ="";
  getPerformanceLevel2APICallId:string ="";
  getPerformanceLevel3APICallId:string ="";
  getSubAssetForLevel2APICallId:string = "";
  getIndividualAllocationAPICallId: string = "";
  getAllAssetMatrixAPICallId:string = "";
  getSubAssetForCurrencyLevel2APICallId: string = "";
  getIndividualCurrencyAllocationAPICallId:string = "";
  getIndividualCountryAllocationAPICallId: string = "";
  getUserProfileAPICallId: string = "";
  getAccessPermissionAPICallId: string = "";
  
  getCurrencyPieChart = async () => {
    const clientId = await storage.get("clientId")
     this.getCurrencyPieChartAPICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_dashboard/dashboards/country_exposure_pie_chart?id=${clientId}`,
      method: "GET",
      contentType: "application/json"
    });
  }

  handleGetCurrencyPieChartResponse = (apiCallId: string,response: CurrencyPieChartData) => {
    if(apiCallId === this.getCurrencyPieChartAPICallId){
      const dataArray = Object.keys(response.data)
      .map(dataInfo => ({
        name: dataInfo,
        value: response.data[dataInfo]
      }))
      .filter(entry => entry.value !== 0.0).sort((itemA, itemB) => itemA.value - itemB.value);;;
      this.setState({
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartPositions: [],
        currencyPieChartInfo:{
          currency_symbol: response.currency_symbol,
          total_networth: response.total_networth 
        }
      })
    }
  }

  PIE_CURRENCY_COLORS = ["#6A32F1","#814FF9","#8D60F8","#B79BF8","#5D29D9","#A17BF9","#D2C2F8",'#E6DDFC',"#763DFF","#AE9ED4","#490ED7"];

  handleAllocationChartCurrencyChange = () => {
    let assetName = this.state.currentLevel.previousName.toLowerCase();
    if(assetName === "luxury"){
      assetName = 'portable'
    }
    const asset = getPerformanceAssetDistribution(assetName)    
    this.getPerformanceLevel3(this.state.currentLevel.realName as string, asset, this.state.performanceFilter)
    this.getIndividualAssetsAllocations((this.state.currentLevel.realName as string),assetName, this.state.current_asset_id);
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("auhtToken")
    const { contentType, method, endPoint, body } = data;
    const dashboardMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    dashboardMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    dashboardMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    dashboardMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && dashboardMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(dashboardMessage.id, dashboardMessage);
    return dashboardMessage.messageId;
  };

  changeType = (type:string) =>{
    this.setState({
      assetsType: type
    },()=>{
      this.handleRefreshDashboard()
    });
  }

  getCountryPieChart = async () => {
    const clientId = await storage.get("clientId")
    this.getCountryPieChartAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_dashboard/dashboards/all_assets_pie_chart?pie_chart_type=country&id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
 }

 handleGetCountrypieChartLevel2Response = (apiCallId: string, response: {
    total: number,
    data: {
        country: string,
        percentage: number
    }[],
    currency: string,
    symbol: string
 }) => {
  if (apiCallId === this.getCountryPieChartLevel2APICallId) {
    const dataArray = response.data.map(country => ({
      name: country.country,
      value: Math.round(country.percentage * 100) / 100
    })).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
    this.setState({
      currencyPieChartData: dataArray,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
      currencyPieChartInfo: {
        total_networth: response.total,
        currency_symbol: response.symbol
      },
    })
  }
 }

  handleGetCountrypieChartResponse = (apiCallId: string, response: {
    total_networth: number,
    symbol: string,
    piechart: {
      "name": string,
      "code": string,
      "balance": number,
      "percentage": string
    }[]
  }) => {
    if (apiCallId === this.getCountryPieChartAPICallId) {
      const dataArray = response.piechart.map(country => ({
        name: country.name,
        value: parseFloat(country.percentage)
      })).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);;;
      this.setState({
       
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total_networth,
          currency_symbol: response.symbol
        },
      })
    }
  }
  getDivercificationScore = async () => {
    const clientId = await storage.get("clientId")
    this.getDivercificationScoreAPICallId = await this.apiCall({
     body: null,
     endPoint: `/bx_block_cfdiversificationscore1/diversifications/score?account_id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }
  
  handleGetDivercificationResponse = (apiCallId: string, response: {
    "score": number
  }) => {
    if (apiCallId === this.getDivercificationScoreAPICallId) {
      this.setState({
        allocationPercentage: response.score,
      })
    }
  }

  getAssetsAllocations = async () => {
    const clientId = await storage.get("clientId");
    this.getAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_dashboard/dashboards/all_assets_pie_chart?pie_chart_type=assets&id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }
  
  handleGetAssetAllocationResponse = (apiCallId: string, response: {
    total_networth: number,
    symbol: string,
    piechart: {
      "name": string,
      "code": string,
      "balance": number,
      "id": string,
      "percentage": string
    }[]
  }) => {
    if (apiCallId === this.getAllocationAPICallId) {
      const dataArray = response.piechart.map(assets => {
        let name = assets.name;
        if(name === "Portable"){
          name = "Luxury"
        }
        if(name === "Shares"){
          name = "Shares & Funds"
        }
        return ({
          name: name,
          asset_id: assets.id,
          value: parseFloat(assets.percentage)
        })
      }).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        allocationPieChart: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total_networth,
          currency_symbol: response.symbol
        },
      })
    }
  }


  onPieEnter= async (_data:{},index:number) =>{
    this.setState({
      activeIndex: index,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  getColor = (name:string) =>{
    switch (name.toLowerCase()) {
        case 'bank accounts':
          return '#4db7fe';
        case 'commodity':
          return '#48970e';
        case 'cash':
          return '#c8c822';
        case 'shares & funds':
          return '#8d8998';
        case 'stocks':
          return '#2f92fc';
        case 'crypto currency':
          return '#7849e8';
        case 'property':
          return '#df6767';
        case 'luxury':
          return '#e82c81';
        case 'pension':
          return '#068d84';
        case 'liability':
          return '#d44bc7';
        default:
          return '#2ce2d5'; 
      }
  }

  onCurrencyPieEnter= async (_data:{},index:number) =>{
    this.setState({
      activeCurrencyIndex: index,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  onCurrencyPieOut= async (_data:{},index:number) =>{
    this.setState({
      activeCurrencyIndex: undefined,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  onPieLeave= async() =>{
    this.setState({
      activeIndex: undefined,
      allocationPieChartPositions: [],
      currencyPieChartPositions: [],
    })
  }

  getPeformance = async (performanceFilter:string) => {
    const clientId = await storage.get("clientId");
    this.getPerformanceAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_cfdashboard6/dashboards/performance_graph?duration=${performanceFilter}&id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }

  getPerformanceLevel2 = async (asset: string, performanceFilter: string) => {
    const clientId = await storage.get("clientId");
    this.getPerformanceLevel2APICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_cfdashboard6/dashboards/asset_performance_sub_graph?asset_type=${asset}&duration=${performanceFilter}&id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }

  getPerformanceLevel3 = async (assetName: string ,asset: string, performanceFilter: string) => {
    let assetNewName = encodeURIComponent(assetName)
    
    const clientId = await storage.get("clientId");
    this.getPerformanceLevel3APICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_cfdashboard6/dashboards/asset_performance_induced_sub_graph?name=${assetNewName}&asset_type=${asset}&duration=${performanceFilter}&id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }
  
  handleGetPeformanceResponse = (apiCallId: string, response: {
    data:{
      name: AssetName;
      data: number[];
    }[],    
    month: string[];
    total_profit_percentage: number;
    total_gain_amount: number;
    asset_count: number;
  }) => {
    if (apiCallId === this.getPerformanceAPICallId) {
      const { data, month } = response;
      const dataArray:BarChartData[] =  month.map((monthName, index) => {
        const result:  BarChartData = { name: monthName } as BarChartData;
        result.direction = this.state.direction
        data.forEach(asset => {
          result[asset.name] = Number(asset.data[index]);
          
        });
        return result;
      });
      this.setState({
        performanceData:dataArray,
      })
        this.setState((prevState:any) => {
      
          return {
            financialSummary2: {
              ...prevState.financialSummary2,
              total_gain: response?.total_gain_amount,
              total_gain_percentage:  response?.total_profit_percentage,
             
            }
          };
        
      });
    }
  }

  handleGetPerformanceLevel2Response = (apiCallId: string, response: {
      data: {
          name: AssetName,
          data: Array<number>
        }[],
      month: Array<string>,
      performance_gain_amount: number,
      performance_profit_percentage: number,
      total_profit_percentage: number,
      total_gain_amount: number,
      monthly_gain: number,
      asset_count: number
      monthly_gain_percentage: number,
  }) => {
    if (apiCallId === this.getPerformanceLevel2APICallId) {
      const { data, month } = response;
      const dataWithUniqueKeys = data.map((item, index) => {
        return {
          ...item,
          uniqueKey: `${item.name} asset ${index}`
        };
      });

      const dataArray1:BarChartData[] =  month.map((monthName, index) => {

        const result:  BarChartData = { name: monthName } as BarChartData;
        result.direction = this.state.direction


        dataWithUniqueKeys.forEach(asset => {
          result[asset.uniqueKey] = Number(asset.data[index]);
        });
        return result;
      });
      
      const barArray = dataWithUniqueKeys.map(value => value.uniqueKey)
      this.setState({
        performanceData: dataArray1,
        performanceBarData: barArray
      })
      this.setState((prevState:any) => {
        return {
          financialSummary2: {
            ...prevState.financialSummary2,
            total_gain: response.performance_gain_amount,
            total_gain_percentage:  response.performance_profit_percentage,
            monthly_gain: response.monthly_gain,
            monthly_gain_percentage: response.monthly_gain_percentage,
            total_gain2: response.total_gain_amount,
            total_gain_percentage2: response.total_profit_percentage,
            total_asset_count: response.asset_count
          }
        };
      });
      if(this.state.financialSummary){
        this.setState({
          financialSummary: {
            ...this.state.financialSummary, 
            total_gain_percentage: response.total_profit_percentage, 
            total_gain: response.total_gain_amount, 
            monthly_gain: response.monthly_gain,
            total_asset_count: response.asset_count,
            monthly_gain_percentage: response.monthly_gain_percentage
          }
        })
      }
    }
  }

  handleGetPerformanceLevel3Response = (apiCallId: string, response: {
    data: {
        name: AssetName,
        data: Array<number>
      }[],
    monthly_profit_percentage: number,
    month: Array<string>,
    performance_gain_amount: number,
    performance_profit_percentage: number,
    total_gain_amount: number,
    total_profit_percentage: number,
    monthly_gain: number,
    asset_count: number
}) => {
  if (apiCallId === this.getPerformanceLevel3APICallId) {
    const { data, month } = response;

    const dataWithUniqueKeys = data.map((item, index) => {
      return {
        ...item,
        uniqueKey: `${item.name} asset ${index}`
      };
    });

    const dataArray2:BarChartData[] =  month.map((monthName, index) => {
      const result:  BarChartData = { name: monthName } as BarChartData;
      result.direction = this.state.direction
      dataWithUniqueKeys.forEach(asset => {
        result[asset.uniqueKey] = Number(asset.data[index]);
      });
      return result;
    });
    
    const barArray = dataWithUniqueKeys.map(value => value.uniqueKey)
    this.setState({
      performanceData: dataArray2,
      performanceBarData: barArray
    })
    this.setState((prevState:any) => {
      return {
        financialSummary2: {
          ...prevState.financialSummary2,
          total_gain: response.performance_gain_amount,
          total_asset_count: response.asset_count,
          monthly_gain: response.monthly_gain,
          monthly_gain_percentage: response.monthly_profit_percentage,
          total_gain2: response.total_gain_amount,
          total_gain_percentage2: response.total_profit_percentage,
          total_gain_percentage:  response.performance_profit_percentage,
        }
      };
    });
    if(this.state.financialSummary){
      this.setState({
        financialSummary: {
          ...this.state.financialSummary, 
          total_gain: response.total_gain_amount, 
          total_gain_percentage: response.total_profit_percentage, 
          total_asset_count: response.asset_count,
          monthly_gain: response.monthly_gain,
          monthly_gain_percentage: response.monthly_profit_percentage
        }
      })
    }
  }
}

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleSetPerformanceFilter = (performanceFilter:string) => {
    this.setState({
      performanceFilter
    })
    if (this.state.currentLevel.level === 1) {
      this.getPeformance(performanceFilter)
    } else if (this.state.currentLevel.level === 2){
      const assetName = getPerformanceAssetDistribution(this.state.currentLevel.name)
      this.getPerformanceLevel2(assetName, performanceFilter)
    } else {
      const currentAsset = getPerformanceAssetDistribution(this.state.currentLevel.name)
      const prevAssetName = getPerformanceAssetDistribution(this.state.currentLevel.previousName)
      this.getPerformanceLevel3(this.state.currentLevel.realName as string, prevAssetName, performanceFilter)
    }
  }

  getStringNewDashboard = (keyvalue: string) => {
    return configJSON.languageListNewDashboard[this.state.language][keyvalue]
  }
  
  performanceBarData = [
    { name: "Commodities", fill: this.getColor("commodity") },
    { name: "Cash", fill: this.getColor("cash") },
    { name: "Bank", fill: this.getColor("bank accounts") },
    { name: "Stocks", fill: this.getColor("stocks") },
    { name: "Crypto", fill: this.getColor("crypto currency") },
    { name: "Shares & Funds", fill: this.getColor("shares & funds") },
    { name: "Luxury", fill: this.getColor("luxury") },
    { name: "Property", fill: this.getColor("property") },
    { name: "Liabilities", fill: this.getColor("liability") },
    { name: "Pension", fill: this.getColor("pension") },
  ]
  
 
  
  hasLevel3Distribution = (asset: string) => {
    const validAssets = ["commodity", "property", "luxury", "liability", "pension", 'bank accounts', 'shares & funds', 'stocks', 'cash', 'crypto currency'];
    if (validAssets.includes(asset)) {
        return true;
    }
    return false;
  }

  handleBack = () => {
    if(this.state.currentLevel.level === 2){
      this.setState({
        currentLevel:{
          level:1,
          name: "Dashboard",
          previousName: "",
          nextLavel:true
        }
      },()=>{
        this.handleRefreshDashboard()
      });
    }else{
      let assetName = this.state.currentLevel.previousName
      this.setState({
        currentLevel:{
          level:2,
          name: assetName,
          previousName: "Dashboard",
          nextLavel:true
        }
      },()=>{
        this.handleRefreshDashboard()
      });
    }
  }
  onPieChartClick = (asset: string, asset_id?:string) => {
    if(asset_id){
      this.setState({ current_asset_id: asset_id })
    }
    // istanbul ignore next
    if (this.state.currentLevel.nextLavel) {
      if (this.state.currentLevel.level === 1) {
        this.setState({
          currentLevel: {
            name: asset, previousName: "Dashboard", level: 1, realName: asset, nextLavel: true
          }
        })
        this.getAssetDistribution(asset.toLowerCase())
      } else {
        let assetCategory = this.state.currentLevel.name.toLowerCase()
        if (this.hasLevel3Distribution(assetCategory)) {
          let assetName = assetCategory.toLowerCase();
          if (assetName === "luxury") { assetName = 'portable' }
          this.getIndividualAssetsAllocations(asset, assetName, asset_id);
          this.getPerformanceLevel3(asset, getPerformanceAssetDistribution(assetName), this.state.performanceFilter)
          this.setState({
            currentLevel: {
              ...this.state.currentLevel, previousName: this.state.currentLevel.name, realName: asset, name: formatText(asset ?? ""),
            }
          })
        }
      }
    }
  }

  getAssetDistribution = async (asset:string) => {
    let assetName = '';
    switch(asset) {
      case "bank accounts": 
        assetName = "bank";
        break;  
      case "shares & funds": 
        assetName = "share";
        break;  
      case "stocks": 
        assetName = "stock";
        break;  
      case "crypto currency": 
        assetName = "crypto";
        break;  
      case "luxury": 
        assetName = "portable";
        break;  
      default:
        assetName = asset;
    }
    const clientId = await storage.get("clientId")
    this.getSubAssetForLevel2APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_dashboard/dashboards/assets_distribution_pie_chart?asset=${assetName}&id=${clientId}`,
      method: "GET",
      contentType: "application/json"
    });
    const performanceAssetName = getPerformanceAssetDistribution(assetName)
    this.getPerformanceLevel2(performanceAssetName, this.state.performanceFilter)
      if(this.state.assetsType === "Currency"){
        this.getAssetCurrencyDistribution(assetName)
      }else{
        this.getAssetCountryDistribution(assetName)
      }
  }

  getAssetCurrencyDistribution = async (asset:string) => {
    const clientId = await storage.get("clientId")
    this.getSubAssetForCurrencyLevel2APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_dashboard/new_dashboard_pie_charts/currency_exposure_on_asset_type?asset=${asset}&account_id=${clientId}`,
      method: "GET",
      contentType: "application/json"
    });
  }

  getAssetCountryDistribution = async (asset:string) => {
    const clientId = await storage.get("clientId")
    this.getCountryPieChartLevel2APICallId = await this.apiCall({
      body: null,
      endPoint: `bx_block_dashboard/new_dashboard_pie_charts/country_exposure_on_asset_type?asset=${asset}&account_id=${clientId}`,
      method: "GET",
      contentType: "application/json"
    });
  }
   
  handleGetSubGraphAssetAllocationResponse = (apiCallId: string, response: {
    next_level: boolean;
    total: number,
    symbol: string,
    data: {
      "name": string,
      "id": string,
      "percentage": number
    }[]
  }) => {
    if (apiCallId === this.getSubAssetForLevel2APICallId) {
      const dataArray = response.data.map(assets => ({
        name: assets.name,
        asset_id: assets.id,
        value: Number(assets.percentage?.toFixed(2))
      })).filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        allocationPieChart: dataArray,
        currentLevel:{
          ...this.state.currentLevel,
          level: 2,nextLavel:response.next_level
        },
        allocationPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total,
          currency_symbol: response.symbol
        },
      })
    }
  }
  
   getFundTypeText = () => {
    return this.state.currentLevel.level === 1 ? `${this.state.allocationPieChart.length} ${this.getStringNewDashboard("fundType")}` : '';
  };
   
  getIndividualAssetsAllocations = async (name:string,type:string, asset_id?: string) => {
    const clientId = await storage.get("clientId")


    let newType = type;
    if (type == 'bank accounts') {
      newType = 'Bank'
    }
    if (type == 'shares & funds') {
      newType = encodeURIComponent(type)
    }
    if (type == 'crypto currency') {
      newType = 'crypto'
    }
    if (type === 'stocks') {
      newType = 'Stocks'
    }

    let asset_and_name = `&asset_id=${asset_id}&name=${encodeURIComponent(name)}`;

    this.getIndividualAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: `${configJSON.individualAssetEndpoint}?type=${newType + asset_and_name}&id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });

    if (this.state.assetsType === "Currency") {
      this.getIndividualAssetCurrencyDistribution(name, type)
    } else {
      this.getIndividualAssetCountryDistribution(name, type)
    }
  }

  handleGetSubGraphIndividualAssetAllocationResponse = (apiCallId: string, response: {
    next_level: boolean;
    total: number,symbol: string,data: {"number": string,"percentage": number,"name": string, "id":string}[]
  }) => {
    if (apiCallId === this.getIndividualAllocationAPICallId) {
      const dataArray = response.data.map(assets => ({
        name: assets?.name || this.state.currentLevel.realName as string,
        asset_id: assets.id,
        value: assets.percentage
      })).filter(asset=>asset.value>0);
      this.setState({
        currencyPieChartPositions: [],
        allocationPieChart: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total, currency_symbol: response.symbol
        },
        currentLevel:{
          ...this.state.currentLevel,level: 3, nextLavel:response.next_level
        }
      })
    }
  }

  getAllAssetMatrix = async () => {
    const clientId = await storage.get("clientId")
    this.getAllAssetMatrixAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_cfdashboard6/dashboards/gain_calculation?id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }

  handleGetAllAssetMatrixResponse = (apiCallId: string, response: IFinancialSummary) => {
    if (apiCallId === this.getAllAssetMatrixAPICallId) {
      this.setState({
        financialSummary: {
          ...response,
          total_gain_percentage: response?.total_gain_percentage ? response?.total_gain_percentage: 0,
        },
      })
    }
  }

  getAccessPermission = async () => {
    const clientId = await storage.get("clientId")
    this.getAccessPermissionAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_formapprovalworkflow/wealth_planners/get_access_permission?account_id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }

  handleGetAccessPermissionResponse = (apiCallId: string, response: {
    allow_access: boolean,
    access_permission_after_service: null
  }) => {
    if (apiCallId === this.getAccessPermissionAPICallId) {
      if ("allow_access" in response) {
        this.setState({
          hasPermission: response.allow_access,
        })
      }
    }
  }

  handleGetIndividualCurrencyAllocationResponse = (apiCallId: string, response: {
    total: number, symbol: string, data: { "currency": string, "percentage": number }[]
  }) => {
    if (apiCallId === this.getIndividualCurrencyAllocationAPICallId) {
      const dataArray = response.data.map(assets => ({
        value: parseFloat(assets.percentage.toFixed(2)),
        name: assets.currency,
      }))
        .filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        currencyPieChartInfo: {
          total_networth: response.total,
          currency_symbol: response.symbol,
        },
        currencyPieChartPositions: [],
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],

      })
    }
  }

  handleGetSubGraphCurrencyAllocationResponse = (apiCallId: string, response: {
    total: number,
    symbol: string,
    data: {
      "currency": string, "percentage": number,
    }[]
  }) => {
    if (apiCallId === this.getSubAssetForCurrencyLevel2APICallId) {
      const dataArray = response.data.map(assets => ({
        name: assets.currency,
        value: parseFloat(assets.percentage.toFixed(2))
      }))
        .filter(entry => entry.value > 0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        currencyPieChartPositions: [],
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        currencyPieChartInfo: {
          total_networth: response.total,
          currency_symbol: response.symbol,
        }
      })
    }
  }

  

  handleGetIndividualCountryAllocationResponse = (apiCallId: string, response: {
    data: {
        country: string,
        percentage: number
      }[],
    total: number,
    currency: string,
    symbol: string
  }) => {
    if (apiCallId === this.getIndividualCountryAllocationAPICallId) {
      const dataArray = response.data.map(assets => ({
        value: Math.round(assets.percentage * 100) / 100,
        name: assets.country,
      }))
      .filter(entry => entry.value >0).sort((itemA, itemB) => itemA.value - itemB.value);
      this.setState({
        currencyPieChartInfo:{
          total_networth: response.total,
          currency_symbol: response.symbol,
        },
        currencyPieChartPositions: [],
        currencyPieChartData: dataArray,
        allocationPieChartPositions: [],
        
      })
    }
  }

  getIndividualAssetCurrencyDistribution = async (name:string,type:string) => {
    const clientId = await storage.get("clientId");
    let newType = type;
    if (type === 'bank accounts') {
      newType = 'bank'
    } 
    
    if (type === 'crypto currency') {
      newType = 'crypto'
    }
    if (type === 'shares & funds') {
      newType = 'share'
    }
    this.getIndividualCurrencyAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_dashboard/new_dashboard_pie_charts/currency_exposure_individual_asset_category?type=${newType.toLowerCase()}&name=${encodeURIComponent(name)}&account_id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }

  getIndividualAssetCountryDistribution = async (name:string,type:string) => {
    const clientId = await storage.get("clientId");
    let newType = type;
    if (type === 'bank accounts') {
      newType = 'bank'
    } 
    if (type === 'stocks') {
      newType = 'stock'
    }
    if (type === 'shares & funds') {
      newType = 'share'
    }
    if (type === 'crypto currency') {
      newType = 'crypto'
    }
    this.getIndividualCountryAllocationAPICallId = await this.apiCall({
     body: null,
     endPoint: `bx_block_dashboard/new_dashboard_pie_charts/country_exposure_individual_asset_category?type=${newType.toLowerCase()}&name=${encodeURIComponent(name)}&account_id=${clientId}`,
     method: "GET",
     contentType: "application/json"
   });
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null,
      contentType:"application/json"
    });
  }

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
    }
  }

  navigateRoute = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(message)
  }
   

  handleRefreshDashboard = () => {
    if(this.state.currentLevel.level === 1){
      this.getAssetsAllocations();
      this.getPeformance(this.state.performanceFilter);
      if(this.state.assetsType === "Currency"){
        this.getCurrencyPieChart()
      }else{
        this.getCountryPieChart()
      }
    }else if(this.state.currentLevel.level === 2){      
      this.getAssetDistribution( this.state.currentLevel.name.toLowerCase())
    }else{
     this.handleAllocationChartCurrencyChange()
    }
    this.getAllAssetMatrix()
  }
  // Customizable Area End 
}